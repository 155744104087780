.grammar-modal {
    width: 98%;
    max-width: 1000px;
    /* max-height: 800px; */

}

.grammar-modal__content {
    font-family: 'Roboto';
    max-width: 900px;
    margin: 0 auto;
    padding: 0 15px;
}

.return-button.grammar-modal__return-button {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 2.4rem;
    color: var(--main-color);
}



.grammar__title {
    color: var(--secondary-color);
}

.grammar__title {
    text-align: center;
    font-family: 'Teko';
}

.grammar__text {
    margin: 20px 0;
}

.grammar__topics-wrapper {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
}

.grammar__topic-button {
    width: 27%;
    margin: 10px;
    background-color: transparent;
    font-size: 3rem;
    padding: 15px 25px;
    border: 3px solid var(--main-color);
    border-radius: 7px;
    font-family: 'Teko';
    font-weight: 700;
    color: var(--main-color);
    cursor: pointer;
    transition: .2s;
    min-width: 170px;
}

.grammar__topic-button:hover {
    background-color: var(--main-color);
    color: var(--white-color);
}

.grammar__text {
  color: var(--main-color);  
  margin: 10px 0;
} 

.grammar__topic-name {
    font-family: 'Teko';
    color: var(--main-color);  
    text-align: center;
    font-size: 3.2rem;
}

.grammar__subtopic {
    margin: 30px auto;
}

.grammar__subtopic-name {
    color: var(--secondary-color);
    font-family: 'Teko';
    font-size: 2.8rem;
}



.grammar__table, .grammar__table-head, .grammar__table-data {
    border: 1px solid #000;
    border-collapse: collapse;
    margin: 0 auto;
}

.grammar__table {
    max-width: 800px;
    margin: 20px auto;
}

.grammar__table-header {
    background-color: var(--main-color);
    color: var(--white-color);
}

.grammar__table-header, .grammar__table-data {
    padding: 10px 20px;
    /* width: 30%; */
}

.grammar__table-text-center {
    text-align: center;
}

.grammar__prev-topic-btn, .grammar__next-topic-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: var(--secondary-color);
    font-size: 3.6rem;
}

.grammar__prev-topic-btn {
    left: 10px;
}
.grammar__prev-topic-btn:disabled,.grammar__next-topic-btn:disabled {
    display: none;
}

.grammar__next-topic-btn {
    right: 10px;
}

.grammar__list, .grammar__ordered-list {
    color: var(--main-color);
    padding-left: 25px;
}

.grammar__list-element, .grammar__ordered-list-element {
   margin: 5px 0;
}

.grammar__inner-section {
    margin: 20px 0;
}

@media (max-width: 900px) {
    .grammar__topic-button {
        font-size: 2.4rem;
        padding: 10px;
        margin: 5px;
    }
}