.coloured-speech-bubble {
    max-width: 450px;
    border-radius: 7px;
    padding: 20px;
    background-color: #fff;
    color: var(--main-color);
    position: relative;
    }
  
    .coloured-speech-bubble::after {
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
    }
  
    .coloured-speech-bubble--left-top::after {
      border-right: 20px solid #fff;
      border-left: 20px solid transparent;
      border-top: 20px solid #fff;
      border-bottom: 20px solid transparent;
      left: -40px;
      top: 30px;
    }

    .coloured-speech-bubble--right-top::after {
      
      border-left: 20px solid #fff;
      border-right: 20px solid transparent;
      border-top: 20px solid #fff;
      border-bottom: 20px solid transparent;
      right: -40px;
      top: 30px;
    }

    .coloured-speech-bubble--bottom-right::after {
        border-right: 20px solid #fff;
        border-left: 20px solid transparent;
        border-top: 20px solid #fff;
        border-bottom: 20px solid transparent;
        right: 40px;
        top: 100%;
      }
    