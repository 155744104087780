.expression-learning-activity__expression-wrapper {
    display: flex;
    align-items: center; 
    justify-content: space-between;
    margin: 15px 0;
}

.expression-learning-activity__expression-speaker-button {
    background-color: transparent;
    border: none;
    font-size: 3.2rem;
}

.expression-learning-activity__expression-speaker-button:hover {
    cursor: pointer;
}

.expression-learning-activity__expression-div {
    display: flex;
    width: 35%;
    justify-content: space-between;

}

.expression-learning-activity__expression {
    font-size: 5rem;
    margin-left: 50px;
}

.expression-learning-activity__expression-text {
    font-weight: 700;
}


.expression-learning-activity__image-wrapper {
    width: 300px;
    height: 200px;
    background-position: center;
    background-size: cover;
    border: 2px solid rgb(218, 218, 218);
    border-radius: 5px;
}

.expression-learning-activity__sentence-wrapper {
    text-align: center;
    margin: 30px auto;
    width: 100%;
    max-width: 700px;
}

.expression-learning-activity__sentence-desc {
    color: var(--main-color);
    margin-bottom: 10px
}

.expression-learning-activity__sentence-div {
    border: 2px solid var(--secondary-color);
    border-radius: 5px;
    padding: 20px 25px;
    
}

.expression-learning-activity__sentence {
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expression-learning-activity__sentence-translation {
    font-size: 2.4rem;
}

.expression-learning-activity__sentence-speaker-button {
    background-color: transparent;
    border: none;
    margin-right: 10px;
    font-size: 2rem;
    cursor: pointer;
}

@media (max-width: 590px) {


    .expression-learning-activity__image-wrapper {
        width: 270px;
        height: 180px;
    }

    .expression-learning-activity__expression-div {
        width: auto;
        justify-content: center;
    
    }

    .expression-learning-activity__expression {
        margin-left: 20px;
        font-size: 3.6rem;
    }


    
}

@media (max-width: 500px) {
        .expression-learning-activity__expression-wrapper {
        flex-direction: column;
    }


    .expression-learning-activity__expression-div {
        margin-bottom: 10px;

    } 

    .expression-learning-activity__expression {
        font-size: 3.6rem;
    }

    .expression-learning-activity__image-wrapper {
        width: 270px;
        height: 160px;
    }


    .expression-learning-activity__sentence-div {
        padding: 10px;
        
    }


    .expression-learning-activity__sentence {
        font-size: 2.6rem;

    }
    
    .expression-learning-activity__sentence-translation {
        font-size: 2rem;
    }
}