.main-page-desktop {
  
    min-height: 100vh;
  }
  
  .main-page-desktop__background {
    background-image:  linear-gradient(
      rgba(0, 87, 117, 0.7), 
      rgba(0, 87, 117, 0.7)
    ),
    /* bottom, image */
    url("../backgroundImages/en.webp");
    background-position: center;
    background-size: cover;
    padding: 90px 0 40px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .main-page-desktop__background--en {
    background-image:  linear-gradient(
      rgba(0, 87, 117, 0.7), 
      rgba(0, 87, 117, 0.7)
    ),
    /* bottom, image */
    url("../backgroundImages/en.webp");
  }
  
  .main-page-desktop__background--es {
    background-image:  linear-gradient(
      rgba(0, 87, 117, 0.7), 
      rgba(0, 87, 117, 0.7)
    ),
    /* bottom, image */
    url("../backgroundImages/es.webp");
  }
  
  .main-page-desktop__background--de {
    background-image:  linear-gradient(
      rgba(0, 87, 117, 0.7), 
      rgba(0, 87, 117, 0.7)
    ),
    /* bottom, image */
    url("../backgroundImages/de.webp");
  }

  .main-page-desktop__background--it {
    background-image:  linear-gradient(
      rgba(0, 87, 117, 0.7), 
      rgba(0, 87, 117, 0.7)
    ),
    /* bottom, image */
    url("../backgroundImages/it.webp");
  }
  

  .main-page-desktop__background--nl {
    background-image:  linear-gradient(
      rgba(0, 87, 117, 0.7), 
      rgba(0, 87, 117, 0.7)
    ),
    /* bottom, image */
    url("../backgroundImages/nl.webp");
  }

  .main-page-desktop__background--pl {
    background-image:  linear-gradient(
      rgba(0, 87, 117, 0.7), 
      rgba(0, 87, 117, 0.7)
    ),
    /* bottom, image */
    url("../backgroundImages/pl.webp");
  }

  .main-page-desktop__background--ru {
    background-image:  linear-gradient(
      rgba(0, 87, 117, 0.7), 
      rgba(0, 87, 117, 0.7)
    ),
    /* bottom, image */
    url("../backgroundImages/ru.webp");
  }


  .main-page-desktop__background--fr {
    background-image:  linear-gradient(
      rgba(0, 87, 117, 0.7), 
      rgba(0, 87, 117, 0.7)
    ),
    /* bottom, image */
    url("../backgroundImages/fr.webp");
  }

  .main-page-desktop__background--pt {
    background-image:  linear-gradient(
      rgba(0, 87, 117, 0.7), 
      rgba(0, 87, 117, 0.7)
    ),
    /* bottom, image */
    url("../backgroundImages/pt.webp");
  }

  .main-page-desktop__nav-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  
  .main-page-desktop__logo-wrapper {
    padding: 10px 30px;
    background-color: var(--main-color);
    width: fit-content;
  }
  
  .main-page-desktop__logo {
    height: 50px;
    cursor: pointer;
  }
  
  .main-page-desktop__logout-button {
    background-color: var(--main-color);
    border: none;
    color: var(--white-color);
    padding: 10px 20px;
    text-transform: uppercase;
    font-family: 'Roboto';
    letter-spacing: 1px;
    border-radius: 0 0 0 5px;
    cursor: pointer;
  }
  
  .main-page-desktop__main-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
  }
  
  .main-page-desktop__content-wrapper {
    margin: 0 40px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
  }
  
  .main-page-desktop__content-wrapper:last-of-type {
    align-items: flex-end;
  }
  
    .main-page-desktop__speech-bubble-main-header {
      font-family: 'Teko';
      font-size: 4.8rem;
      font-weight: 400;
      color: var(--main-color);
    }
  
    .main-page-desktop__speech-bubble-header {
      font-family: 'Teko';
      font-size: 4rem;
      font-weight: 400;
      color: var(--secondary-color);
    }
  
    .main-page-desktop__text {
      color: var(--main-color);
      margin: 5px 0;
      line-height: 130%;
    }
  
    .main-page-desktop__content-div {
      background-color: #fff;
      color: var(--main-color);
      border-radius: 7px;
      padding: 10px;
      width: 350px;
    }
  
    .main-page-desktop__content-title {
      display: block;
      color: var(--main-color);
      font-family: 'Teko';
      font-size: 2.6rem;
      padding: 10px 30px;
      text-align: center;
    }
  
    .main-page-desktop__inner-content-wrapper {
      padding: 20px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  
    .main-page-desktop__inner-content-wrapper > * {
      text-align: center;
      color: var(--main-color);
      font-weight: 600;
      font-size: 1.3rem;
    }
  
    .main-page-desktop__activity-data-div {
      margin-bottom: 10px;
    }
  
    .main-page-desktop__activity-data {
      font-size: 3rem;
      font-family: 'Teko';
      color: var(--secondary-color);
    }
  
    .main-page-desktop__levels-wrapper {
      text-align: center;
      width: 100%;
    max-width: 280px;
    margin: 0 auto;
    }
  
    .main-page-desktop__level-buttons {
      margin: 20px 0 10px;
      display: flex;
    justify-content: space-between;
    }
  
    .main-page-desktop__level-button-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
    }
  
    .main-page-desktop__level-button-img{
      width: 60px;
      margin-bottom: 5px;
    }
  
    .main-page-desktop__level-button-img--active {
      width: 90px;
  
    }
  
    .main-page-desktop__level-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      margin: 0 5px;
      color: #fff;
      font-weight: 700;
    }
  
    .main-page-desktop__level-button--active  {
      font-size: 2rem;
    }
    .main-page-desktop__progress-bar {
      width: 100%;
      margin: 5px auto;
    }
    .main-page-desktop__progress-bar-desc {
      color: #fff;
      margin: 5px 0;
    }
  
  .main-page-desktop__lessons-wrapper {
    width: 100%;
    max-width: 280px;
    align-self: center;  
  }
  
    .main-page-desktop__big-square-button {
      
      width: 100%;
      height: 240px;
      border: none;
      border-radius: 7px;
      background-color: var(--secondary-color);
      display: flex;
      align-items: flex-end;
      text-align: left;
      padding: 10px;
      margin-bottom: 15px;
      color: #fff;
      font-weight: 700;
      font-size: 2.3rem;
      text-decoration: none;
    }
  
    .main-page-desktop__transparent-button {
      background-color: transparent;
      border: 2px solid #fff;
      border-radius: 5px;
      align-self: center;
      padding: 20px;
      color: #fff;
      font-size: 2.3rem;
      font-weight: 700;
      width: 100%;
      text-decoration: none;
      display: block;
    }
  
  
  
    .main-page-desktop__courses-list {
      list-style: none;
      margin: 10px;
  
    }
  
    .main-page-desktop__courses-list-element {
      color: var(--main-color);
      font-size: 2.6rem;
      padding: 5px 0;
      display: flex;
      align-items: center;
    }
  
    .main-page-desktop__course-flag {
      width: 50px;
      margin-right: 5px;
    }
  
    .main-page-desktop__show-more-button {
      display: block;
      margin: 0 auto;
      background-color: transparent;
      border: none;
      color: var(--main-color);
      cursor: pointer;
    }
  
  .fun-facts-widget__title {
    font-family: 'Teko';
    color: var(--secondary-color);
    font-size: 3rem;
  }
  
  .fun-facts-widget__content-wrapper {
    display: flex;
    align-items: center;
  }
  
  .fun-facts-widget__img {
    width: 80px;
    margin-right: 10px;
  }
  
  .fun-facts-widget__text {
    font-size: 1.4rem;
    color: var(--main-color);
    text-align: left;
  }
  
  
  @media (max-width: 1190px) {
   .main-page-desktop__content-wrapper {
    margin: 0 10px;
   } 
  
   .main-page-desktop__content-wrapper .coloured-speech-bubble.coloured-speech-bubble--left-top  {
    margin-left: 40px;
   } 
  
   .main-page-desktop__content-div {
    width: 100%;
    max-width: 300px;
  }
  }
  