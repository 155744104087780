.lessons-page__level-bar-wrapper-mobile {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    justify-items: start;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;

}

.lessons-page__level-bar-mobile {
    background-color: var(--first-level-color);
    padding: 5px 15px 0;
    border-radius: 0 0 2px 2px;
    margin: 0 5px 2px;
    text-decoration: none;
}

.lessons-page__logo-wrapper-mobile {
    padding: 10px 10px 3px;
    background-color: var(--main-color);
}

.lessons-page__logo-mobile {
    width: 100px;
}


.lessons-page__levels-wrapper-mobile {
    display: flex;
    align-items: flex-start;
    justify-self: center;
}

.lessons-page__level-bar-mobile--active {
    padding-top: 30px;
}

.lesssons-page__level-number-mobile {
    color: #fff;
    font-size: 2.4rem;
    font-weight: 700;
}

.lessons-page__level-bar-mobile--second {
    background-color: var(--second-level-color);
}

.lessons-page__level-bar-mobile--third {
    background-color: var(--third-level-color);
}

.lessons-page__level-header-mobile {
    color: #fff;
    font-size: 2.4rem;
    letter-spacing: 1px;
}

.lessons-page__user-div-mobile {
    text-align: center;
    background-color: var(--white-color);
    border-radius: 0 0 0 3px;
    padding: 10px;
}

.lessons-page__user-language-img {
    width: 40px;
}



.lessons-page__grammar-buttons-wrapper-mobile {
    position: absolute;
    top: 60px;
    left: 0;
    display: flex;
    flex-direction: column;
}

.lessons-page__grammar-button-mobile {
    background-color: var(--main-color);
    border: none;
    padding: 10px;
    border-radius: 0 0 2px 2px;
    color: #fff;
    font-family: 'Teko';
    font-weight: 600;
    font-size: 2rem;
    width: 50px;
    margin: 4px 0;
    cursor: pointer;
}
