.main-page__courses-modal {

  color: var(--main-color); 
}

.main-page__courses-modal-content {
    text-align: center;
  }

  .main-page__courses-modal-header {
    font-family: 'Teko';
    font-size: 2rem;
  }

  .main-page__courses-modal-your-courses-list, .main-page__courses-modal-other-courses-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 10px 0 20px;
  }

  .main-page__courses-modal-your-courses-list-element, .main-page__courses-modal-other-courses-list-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
  }

  .main-page__courses-modal-your-courses-list-element:hover {
    cursor: pointer;
  }

  .main-page__courses-modal-your-course-flag {
    width: 90px;
    margin-bottom: 5px;
  }

  .main-page__courses-modal-other-course-flag {
    width: 70px;
    margin-bottom: 5px;
    filter: grayscale(60%);
  }

.main-page__courses-modal-info {
  font-size: 1.4rem;
}

.main-page__courses-modal-other-courses-list-element {
  color: #000;
}

.main-page__courses-modal-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.main-page__courses-modal-form-input-label {
  margin: 0 10px;
  font-weight: 700;
}

.main-page__courses-modal-form-input {
  padding: 5px;
  border: 2px solid var(--secondary-color);
  background-color: transparent;
  outline: none;
  border-radius: 3px;
}

.main-page__courses-modal-form-button {
  margin: 0 10px;
}

@media (max-width: 500px) {
  .main-page__courses-modal-form {
    flex-direction: column;
  }

  .main-page__courses-modal-form-input-label,
  .main-page__courses-modal-form-input, 
  .main-page__courses-modal-form-button {
   margin: 5px 0;
  }
  }
