.activity-page__close-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 2.6rem;
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
}

.activity-page__close-button.activity-page__close-button--mobile {
    display: none;
}

.activity-page__lesson-bar {
    background-color: var(--secondary-color);
    padding: 15px 20px 10px;
    border-radius: 0 0 7px 7px;
    margin: 0 auto;
    width: fit-content;
}

.activity-page__lesson-header {
    color: #fff;
    font-size: 2.4rem;
    letter-spacing: 1px;
    font-family: 'Teko';
}

.activity-page__content-wrapper {
    width: 95%;
    max-width: 900px;
    margin: 0 auto;
    padding-bottom: 80px;
}

.activity-page__activity-title-wrapper {
    margin: 20px auto;
    display: flex;
    align-items: center;
}

.activity-page__square-div {
    width: 45px;
    height: 45px;
    background-color: var(--secondary-color);
    border-radius: 5px;
}

.activity-page__activity-title-div {
    background-color: rgb(211, 211, 211);
    padding: 5px;
    width: 100%;
    border-radius: 0 5px 5px 0;
    padding-left: 10px;
}

.activity-page__activity-title {
    font-weight: 700;
}

.activity-page__squares-wrapper {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    margin: 0 auto;
    padding: 10px 0;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

.activity-page__square {
    width: 32px;
    height: 32px;
    background-color: rgb(211, 211, 211);
    border-radius: 5px;
    margin: 0 2px;
}

.activity-page__square--active {
    width: 36px;
    height: 36px;
}

.activity-page__square--completed {
    background-color: var(--green-color);
}

.activity-page__square--failed {
    background-color: var(--red-color);
}

.activity-page__activity_wrapper {
    position: relative;
    max-width: calc(100% - 80px);
    margin: 0 auto;
}

.activity-page__activity-change-button {
    position: absolute;
    background: none;
    outline: none;
    border: none;
    color: var(--secondary-color);
    cursor: pointer;
    font-size: 40px;
    top: 50%;
    transform: translateY(-50%);
}

.activity-page__activity-change-button:disabled {
    color: #ddd;
}

.activity-page__activity-change-button--left {
    left: -40px;
}

.activity-page__activity-change-button--right {
    right: -40px;
}

.activity-page__square--in-progress {
    background-color: var(--secondary-color);
}

.activity-page__question-button-wrapper {
    position: fixed;
    z-index: 103;
    bottom: 10px;
    right: 10px;
}

@media (max-width: 600px) {

    .activity-page__activity_wrapper {
        max-width: 100%;
    }

    .activity-page__activity-change-button {
        position: fixed;
        top: unset;
        bottom: 30px;
    }

    .activity-page__activity-change-button--left {
        left: 10px;
    }
    
    .activity-page__activity-change-button--right {
        right: 10px;
    }

    .activity-page__close-button {
        display: none;
    }

    .activity-page__close-button.activity-page__close-button--mobile {
        position: static;
        margin-right: 10px;
        display: block;
    }

    .activity-page__lesson-bar {
        padding: 5px 5px 2px;
        border-radius: 0;
        margin: 0 auto;
        width: 100%;
        text-align: center;
    }

    .activity-page__lesson-header {
        font-size: 2.2rem;
    }
}
