.navbar {
    display: flex;
    align-items: center;
    position: relative;
}

.navbar__logo-wrapper {
    padding: 10px 30px;
    background-color: var(--main-color);
    width: fit-content;
}

.navbar__logo {
    height: 50px;
    cursor: pointer;
}

.navbar__menu-list {
    list-style: none;
    display: flex;
    margin-left: 20px;
}

.navbar__menu-link {
    text-decoration: none;
    color: var(--white-color);
    margin: 0 15px;
}

.navbar__menu-link:hover {
    color: #fff;
} 

.navbar__menu-bar {
    display: none;
}

@media (max-width: 700px) {
    .navbar {
        justify-content: space-between;
    }

    .navbar__menu-bar {
        display: block;
        margin-right: 10px;
        color: var(--white-color); 
        font-size: 2rem;
    }
    
.navbar__menu-list {
    position: absolute;
    left: 100%;
    top: 100%;
    z-index: 100;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 0;
    padding: 5px 0;
    background-color: rgba(2, 80, 114, .9);
    transition: transform .2s;
}

.navbar__menu-list--open {
    transform: translateX(-100%);
}

.navbar__menu-list-element {
    padding: 10px 0;
}


}