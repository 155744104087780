.test-page__close-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 2.6rem;
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
}

.test-page__close-button.test-page__close-button--mobile {
    display: none;
}

.test-page__lesson-bar {
    background-color: var(--secondary-color);
    padding: 15px 20px 10px;
    border-radius: 0 0 7px 7px;
    margin: 0 auto;
    width: fit-content;
}

.test-page__lesson-header {
    color: #fff;
    font-size: 2.4rem;
    letter-spacing: 1px;
    font-family: 'Teko';
}

.test-page__content-wrapper {
    width: 95%;
    max-width: 900px;
    margin: 0 auto;
}

.test-page__test-title-wrapper {
    margin: 20px auto;
    display: flex;
    align-items: center;
}

.test-page__square-div {
    width: 45px;
    height: 45px;
    background-color: var(--secondary-color);
    border-radius: 5px;
}

.test-page__test-title-div {
    background-color: rgb(211, 211, 211);
    padding: 5px;
    width: 100%;
    border-radius: 0 5px 5px 0;
    padding-left: 10px;
}

.test-page__test-title {
     font-weight: 700;
}

.test-page__test-content {
    margin: 40px 0;
}

.test-page__test-content-text {
    margin: 40px auto;
    max-width: 800px;
}

.test-page__test-content-text-span-yellow {
    font-size: 2rem;
    font-weight: 600;
    color: var(--secondary-color);
}

.test-page__test-content-text-span-green {
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--green-color);
}

.test-page__progress-bar-wrapper {
    margin: 40px 0;
}

.test-page__test-content-buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.test-page__test-content-button {
    margin: 0 10px;
    font-weight: 400;
    font-size: 1.6rem;
    border: 1px solid var(--secondary-color);
}

.test-page__test-content-button--transparent {
    background-color: transparent;
    color: var(--secondary-color)
}

.test-page__squares-wrapper {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    margin: 0 auto;
    padding: 10px 0;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

.test-page__square {
    width: 32px;
    height: 32px;
    background-color: rgb(211, 211, 211);
    border-radius: 5px;
    margin: 0 2px;
}

.test-page__square--active {
    width: 36px;
    height: 36px;
}

.test-page__square--completed {
    background-color: var(--green-color);
}

.test-page__square--failed {
    background-color: var(--red-color);
}


.test-page__square--in-progress {
    background-color: var(--secondary-color);
}


    


@media (max-width: 600px) {
    .test-page__close-button {
        display: none;
    }

    .test-page__close-button.test-page__close-button--mobile {
        position: static;
        margin-right: 10px;
        display: block;
    }

    .test-page__lesson-bar {
        padding: 5px 5px 2px;
        border-radius: 0;
        margin: 0 auto;
        width: 100%;
        text-align: center;
    }

    .test-page__lesson-header {
        font-size: 2.2rem;
    }
}

@media (max-width: 450px) {
    .test-page__test-content-buttons-wrapper {
        flex-direction: column;
    }

    .test-page__test-content-button {
        margin: 5px 0;
    }
}