.lessons-page {
    font-family: 'Teko';

  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  padding-bottom: 20px;
}




.lessons-page__return-button {
    color: var(--white-color);
    position: fixed;
    top: 10px;
    left: 15px;
    z-index: 100;
}


.lessons-page__lists-wrapper {
    position: relative;
    top: 80px;
    height: calc(100vh - 80px);
    overflow-x: scroll;
}

.lessons-page__lessons-list {
    min-height: 90px;
    list-style: none;
    width: fit-content;
    margin: 20px auto;
}

.lessons-page__lesson {
    display: flex;
    align-items: center;
    width: 98vw;
    max-width: 600px;
}

.lessons-page__lesson-image-wrapper {
    width: 150px;
    background-position: center;
    background-size: cover;
    height: 95px;
    border: 2px solid var(--white-color);
    border-radius: 4px;
}

.lessons-page__lesson-desc {
    border: 2px solid var(--white-color);
    border-left: none;
    width: calc(100% - 150px);
    height: fit-content;
    border-radius: 0 3px 3px 0;
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.lessons-page__lessons-desc--expanded {
    border-color: var(--secondary-color);
    height: 95px;
}

.lessons-page__lessons-desc--expanded .lessons-page__progress-bar-wrapper {
    display: flex;
}

.lessons-page__lesson-name {
    color: #fff;
}


.lessons-page__progress-bar-wrapper {
    width: 50%;
    align-items: center;
    justify-content: center;
    margin: 10px 0 5px;
    display: none;
}

.lessons-page__progress-bar {
    width: 80%;
    margin: 5px auto;
  }

.lessons-page__progress-percent {
    color: #fff;
    font-family: sans-serif;
}

.lessons-page__sublessons-list {
    list-style: none;
    margin: 10px 0;
}

.lessons-page__sublesson {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    background-color: rgb(226, 226, 226);
    
    border-radius: 3px;
    width: 90%;
    margin: 10px auto;
}

.lessons-page__sublesson-image-wrapper {
    width: 90px;
    height: 75px;
    background-color: var(--green-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.lessons-page__sublesson-desc-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.lessons-page__sublesson-name {
    font-size: 2rem;
    margin-bottom: 5px;
}

.lessons-page__sublesson-time {
    font-family: sans-serif;
}


.lessons-page__sublesson-button {
    background-color: var(--green-color);
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 3px;
    font-size: 1.6rem;
    text-decoration: none;
}

.lessons-page__sublesson-button:hover {
    cursor: pointer;
}

.lessons-page__sublesson-button[disabled] {
    pointer-events: none;
  cursor: default;
}

.lessons-page__test-wrapper {
    width: 88vw;
    max-width: 600px;
    margin: 40px auto 30px;
    border: 2px solid var(--secondary-color);
    border-radius: 3px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lessons-page__test-wrapper--disabled {
    border: 2px solid var(--white-color);
    pointer-events: none;

}


.lessons-page__test-text {
    font-family: 'Teko';
    font-size: 3rem;
    font-weight: 600;
    color: var(--white-color);
    letter-spacing: 1px;
}

.lessons-page__test-button {
    margin: 0;
}

.lessons-page__test-button {
    background: var(--green-color);
}

.lessons-page__test-wrapper--disabled .lessons-page__test-button {
    background: #ccc;
}


@media (max-width: 700px) {
    .lessons-page__lesson {
        flex-direction: column;
    }

    .lessons-page__lesson-desc {
        border: 2px solid var(--white-color);
        width: 90%;
        border-radius: 3px;
    }

    .lessons-page__lessons-desc--expanded {
        border-color: var(--secondary-color);
        border-radius: 3px;
    }
    
    .lessons-page__return-button {
        display: none;
    }

    .lessons-page__test-wrapper {
        max-width: 540px;
        flex-direction: column;
    }

    .lessons-page__test-text {
        margin-bottom: 10px;
        text-align: center;
        font-size: 2.6rem;
    }

}