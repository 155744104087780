.your-activity__content-div {
    background-color: #fff;
    color: var(--main-color);
    width: 100%;
    max-width: 350px;
    border-radius: 0 0 10px 10px;
  }

  .your-activity__content-title {
    display: block;
    color: var(--main-color);
    font-family: 'Teko';
    font-size: 3rem;
    padding: 10px 30px;
    text-align: center;
  }

  .your-activity__inner-content-wrapper {
    padding: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .your-activity__inner-content-wrapper > * {
    text-align: center;
    color: var(--main-color);
    font-weight: 600;
    font-size: 1.3rem;
  }

  .your-activity__activity-data-div {
    margin-bottom: 10px;
  }

  .your-activity__activity-data {
    font-size: 3rem;
    font-family: 'Teko';
    color: var(--secondary-color);
  }

  .your-activity__chart {
     width: 70%;
     min-height: 150px;
  }

