.platform-end-modal {
  width: 90%;
  max-width: 900px;
  color: var(--main-color);
  border-radius: 0;
  padding: 10px 5px 5px;
}

.platform-end-modal__header {
  margin-bottom: 20px;
}

.platform-end-modal__button {
  margin-top: 10px;
}

.platform-end-modal_wrapper {
    padding: 20px;
}