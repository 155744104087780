.alphabet-modal {
    width: 90%;
    max-width: 1000px;

}

.alphabet-modal__content {
    font-family: 'Roboto';
}


.alphabet-modal__title, .alphabet-modal__text {
    color: var(--main-color);
}

.alphabet-modal__title {
    text-align: center;
    font-family: 'Teko';
}

.alphabet-modal__text {
    margin: 20px 0;
}

.alphabet-modal__table, .alphabet-modal__table-head, .alphabet-modal__table-data {
    border: 1px solid #000;
    border-collapse: collapse;
    text-align: center;
    margin: 0 auto;
}

.alphabet-modal__table-header {
    background-color: var(--main-color);
    color: var(--white-color);
}

.alphabet-modal__table-header, .alphabet-modal__table-data {
    padding: 5px 20px;
}