*, *::after, *::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  --main-color: #025072;
  --secondary-color: #33A7DC;
  --white-color: rgb(245, 245, 245);
  --gray-color: rgb(235, 235, 235);
  --green-color: #95C34B;
  --red-color: rgb(231, 50, 50);
  --first-level-color: #FCCC42;
  --second-level-color: #F0A14B;
  --third-level-color: #379299;
  scroll-behavior: smooth;
overflow-x: hidden;
}

a{
  text-decoration: none;
}

body {
  font-size: 1.6rem;
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
}

.progress-bar > div {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 10px;
  height: 6px;
  /* background: rgb(200, 200, 200); */
}
 
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;
}
 
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(200, 200, 200, .5);
}

::-webkit-scrollbar-thumb:hover{
  background: rgba(200, 200, 200, .8);
}

::-webkit-scrollbar-thumb:active{
  background: rgba(200, 200, 200, .7);
}

.btn:focus {
  outline: none;
  box-shadow: none;
}
.btn{
	box-shadow: none !important
}
.dropdown-toggle::after {
  display: none !important; 
}

.form_wrapper {
  padding: 30px 30px;
  
}
@keyframes move-text {
  0% { bottom: -0.2em; opacity: 1; }
  50% { bottom: 0.2em; }
  100% { bottom: 0; opacity: 1; }
}

.form_wrapper .form_field input {
  border: 2px solid #012440 !important;
  color: #012440 !important;
  background-color: #fff3c3;
}

.container {
  position : relative;
  width : 1100px;
  display : flex;
  align-items : center;
  justify-content : center;
  flex-wrap: wrap;
  padding : 30px;  
}

.container .card {
  position: relative;
  max-width : 300px;
  height : 215px;  
  background-color : #fff;
  margin : 30px 10px;
  padding : 20px 15px;
  
  display : flex;
  flex-direction : column;
  box-shadow : 0 5px 20px rgba(0,0,0,0.5);
  transition : 0.3s ease-in-out;
  border-radius : 15px;
}
.container .card:hover {
  height : 320px;    
}

.navAnimation:hover {
  color: #899ed1;
  transform: scale(1.1);
}
.container .card .image {
  position : relative;
  width : 260px;
  height : 260px;
  
  top : -40%;
  left: 8px;
  box-shadow : 0 5px 20px rgba(0,0,0,0.2);
  z-index : 1;
}

@media only screen and (min-width: 600px){
.showDescriptionFirst:hover .descriptionToshowFirst{
  display: block;
}

.showDescriptionSecond:hover .descriptionToshowSecond{
  display: block;
}

.showDescriptionThird:hover .descriptionToshowThird{
  display: block;
}

.showDescriptionFourth:hover .descriptionToshowFourth{
  display: block;
}

.showDescriptionFifth:hover .descriptionToshowFifth{
  display: block;
}
}

@media only screen and (max-width: 600px){
.showDescriptionFifth:active .descriptionToshowFifth{
  display: block;
  }

  .showDescriptionFirst:active .descriptionToshowFirst{
    display: block;
  }
  
  .showDescriptionSecond:active .descriptionToshowSecond{
    display: block;
  }
  
  .showDescriptionThird:active .descriptionToshowThird{
    display: block;
  }
  
  .showDescriptionFourth:active .descriptionToshowFourth{
    display: block;
  }
}

.border {
  border: 1px solid white;
  padding: 10px;
  border-radius: 10px;
}

.border:hover{
  border: none !important;
  padding: 0px;
  border-radius: 10px;
}

.btn{
	box-shadow: none !important
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
.Dropdown-control{
  background-color: transparent !important;
  border: none !important;
  color: white !important;
  width: fit-content !important;
  transform: scale(1.1) !important;
  display: flex  !important;
  justify-content: center  !important;
  padding: 5px  !important;
}
.Dropdown-placeholde{
  display: flex  !important;
  width: 250px !important;

}

.Dropdown-root{
  width: fit-content !important;
}

.Dropdown-placeholde {
  display: none;
}

.Dropdown-items{
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
}
.Dropdown-arrow{
  display: none !important;
}

.Dropdown-placeholder div p{
  display: none !important;
}

.Dropdown-menu{
  border-radius: 10px !important;
  border: none !important;
  width: 150px  !important;
  right: 0px  !important;
  margin-top: 10px !important;
}

.Dropdown-root:hover{
  border-bottom: none !important;
}

.Dropdown-control:hover{
  box-shadow: none !important;
}