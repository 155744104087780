.lessons-page__level-bar-wrapper-desktop {
    display: flex;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    background-color: transparent;
    width: 100%;
}

.lessons-page__level-bar-desktop {
    background-color: var(--first-level-color);
    padding: 15px 20px 10px;
    border-radius: 0 0 7px 7px;
    margin: 0 5px;
    text-decoration: none;
}


.lessons-page__level-bar-desktop .lessons-page__level-header-desktop {
    display: none;
}

.lessons-page__level-bar-desktop--active .lessons-page__level-header-desktop {
    display: block;
}

.lessons-page__level-bar-desktop--active .lesssons-page__level-number-desktop {
    display: none;
}

.lesssons-page__level-number-desktop {
    color: #fff;
    font-size: 2.4rem;
    font-weight: 700;
}

.lessons-page__level-bar-desktop--second {
    background-color: var(--second-level-color);
}

.lessons-page__level-bar-desktop--third {
    background-color: var(--third-level-color);
}

.lessons-page__level-header-desktop {
    color: #fff;
    font-size: 2.4rem;
    letter-spacing: 1px;
}

.lessons-page__grammar-buttons-wrapper-desktop {
    margin-left: 15px;
}

.lessons-page__grammar-button-desktop {
    background-color: var(--main-color);
    border: none;
    padding: 10px;
    border-radius: 0 0 2px 2px;
    color: #fff;
    font-family: 'Teko';
    font-weight: 600;
    font-size: 2rem;
    width: 50px;
    margin: 0 4px;
    cursor: pointer;
}
